<template>
  <div class="home">
    <Header :isBook='isBook' :isArrival='true'/>
    <div class="info-wrapper">
      <div class="delivery-info">
        <div class="delivery-info--content">
          <div class="orange-box">Arrival Info</div>
          <h3 class="direction">{{ getOrderInfo.from }} to {{ getOrderInfo.to }}</h3>
          <div class='container-info'>
            <Popup :fieldName='"No. of bags*"' :header='"Bag types"'/>
            <!-- <input class="input_full-width" type="text" v-model="numberOfBags" v-on:change="info" required><br> -->
            <Select @vue-selected="setNumberOfBags" :selectedNum="getOrderInfo.numberOfBags" :count="20" :disOption="0" required />
            <small v-if="/\D/.test(numberOfBags) || numberOfBags === '' || numberOfBags > maxNumberOfBags">
              This value can only be integer, no more than {{ this.maxNumberOfBags }}, and cannot be empty
            </small><br>
            <Popup :fieldName='"No. of odd-size"' :header='"Bag types"'/>
            <!-- <input class="input_full-width" type="text" v-model="numberOfHeavyBags" v-on:change="info" required><br> -->
            <Select @vue-selected="setNumberOfHeavyBags" :selectedNum="getOrderInfo.numberOfHeavyBags" :count="6" :disOption="0" required />
            <small v-if="/\D/.test(numberOfHeavyBags) || numberOfHeavyBags > maxNumberOfBags">
              This value can only be integer or empty and no more than {{ this.maxNumberOfBags }}
            </small><br>
            <slot v-if="getOrderInfo.numberOfHeavyBags > 0">
              <div class="odd-size">
                <p class="list-item">What kind of odd-size luggage?</p><br>
                <input class="input_full-width" type="text" v-model="oddSize" v-on:change="info" placeholder="e.g. bicycle, golfbag" required><br>
                <small v-if="getOrderInfo.numberOfHeavyBags > 0 && !getOrderInfo.oddSize">
                  This field must be specified if odd-size luggage is selected
                </small><br>
              </div>
            </slot>
          </div>
          <div class='container-info'>
            <div class="calendar-label basic">
              <Popup
                v-if="getOrderInfo.from === 'Private Address' || (getOrderInfo.from === 'Hotel' && getOrderInfo.to !== 'Airport' && getOrderInfo.to !== 'Cruise')"
                :fieldName='"Transfer Day*"'
                :header='"Transfer Day"'
              />
              <Popup
                v-else-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport'"
                :fieldName='"Transfer Day* (Call us for same day service)"'
                :header='"Transfer Day (to Airport)"'
              />
              <p v-else-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Cruise'">Transfer Day* (Call us for same day service)</p>
              <p v-else-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Hotel'">Cruise disembarkation date*</p>
              <p v-else>Arrival Date*<br>Call us for same day service</p>
            </div>
            <Calendar :calendarId='1' :placeholder='"e.g. 2021-12-21"' :disabled="$route.query.currentDate" />
            <div v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport'">
              The luggage must be at the reception from 8.30 am.
            </div>
            <div
              class="calendar-label basic"
              v-if="getOrderInfo.from !== 'Cruise' &&
                (getOrderInfo.to !== 'Hotel' || getOrderInfo.to !== 'Private Address') &&
                (getOrderInfo.from !== 'Hotel' && getOrderInfo.to !== 'Cruise') &&
                (getOrderInfo.from !== 'Airport' && getOrderInfo.to !== 'Hotel')"
            >
              <br>{{ getOrderInfo.from === "Private Address" || getOrderInfo.from === "Hotel" ? "Your pick-up time at the airport*" : "Arrival Time*" }}
            </div>
            <div v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Private Address'" class="calendar-label basic">
              <p>Arrival Time*</p>
            </div>
            <div v-else-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Hotel'">
              <Popup
                :fieldName='"Arrival Time*"'
                :header='"Arrival Time"'
              />
            </div>
            <div v-else-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport'" class="calendar-label basic">
              <br>{{ getOrderInfo.from === "Private Address" || getOrderInfo.from === "Hotel" ? "Your pick-up time at the airport*" : "Arrival Time*" }}
            </div>
            <p
              class="calendar-label basic"
              v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Cruise'"
            >
              Arrival Time*
            </p>
            <div v-if="getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Cruise'">
              <TimePicker
                :infoFrom='getOrderInfo.from'
                :infoTo='getOrderInfo.to'
                :timePickerId='1'
                :disOption='"e.g. 8 am - 9 am"'
              />
            </div>
            <div v-if="getOrderInfo.to !== 'Cruise' && getOrderInfo.from !== 'Cruise' && (getOrderInfo.to !== 'Hotel' || getOrderInfo.to !== 'Private Address')">
              <TimePicker
                :timePickerId='1'
                :infoFrom='getOrderInfo.from'
                :infoTo='getOrderInfo.to'
                :disOption="(getOrderInfo.to === 'Airport' && (getOrderInfo.from === 'Hotel' || getOrderInfo.from === 'Private Address' )) ? 'e.g. 1 pm - 2 pm' : 'e.g. 8 am - 9 am'"
              />
            </div>
            <div v-if="getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Airport'">
              <p class="calendar-label basic">Your pick-up time at the airport*</p>
              <TimePicker
                :timePickerId='1'
                :infoFrom='getOrderInfo.from'
                :infoTo='getOrderInfo.to'
                :disOption='"e.g. 1 pm - 2 pm"'
              />
            </div>
            <div v-if="getOrderInfo.from !== 'Hotel' && getOrderInfo.from !== 'Airport' && getOrderInfo.to === 'Cruise'" class="basic">
              <p>We deliver to your cruise stateroom</p><br>
            </div>
            <div v-if="getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Cruise'">
              <p>The luggage must be at the reception from 8.30 am. We deliver to your cruise stateroom</p>
            </div>
            <slot v-if="(getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Hotel') ||
                        (getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Cruise') ||
                        (getOrderInfo.from === 'Airport' && getOrderInfo.to === 'Private Address')">
              <p class="basic">Arrival Flight Number (if known)</p>
              <ArrivalNumber :arrivalNumberId='1' :from='"ArrivalInfo"'/>
            </slot>
            <slot v-if="(getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Airport') ||
                        (getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Hotel') ||
                        (getOrderInfo.from === 'Private Address' && getOrderInfo.to === 'Cruise')"><br>
              <Popup
                :fieldName='"Private address in Copenhagen *"'
                :header='"Some delivery info"'
              />
              <input class="input_full-width" type="text" v-model="streetNumberPostal" v-on:change="info" required placeholder="Street, number and postal code"><br><br>
              <p class="basic">Apartment identification</p>
              <input
                class="input_full-width"
                type="text"
                v-model="apartment"
                @change="info"
                placeholder="e.g. name on door or apartment number"
              ><br><br>
            </slot>
            <slot v-if="(getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Airport') ||
                        (getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Hotel') ||
                        (getOrderInfo.from === 'Cruise' && getOrderInfo.to === 'Private Address')"><br>
              <p class="basic">Name of Cruise Ship*</p>
              <input class="input_full-width" type="text" v-model="cruiseShip" v-on:change="info" required placeholder="name of cruise ship"><br><br>
              <p class="basic">Stateroom</p>
              <ArrivalNumber :arrivalNumberId='2' :from='"ArrivalInfo"'/>
            </slot>
            <slot v-if="(getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Airport') ||
                        (getOrderInfo.from === 'Hotel' && getOrderInfo.to === 'Cruise')"><br>
              <p class="basic">Name of hotel in Copenhagen *</p>
              <ArrivalNumber :arrivalNumberId='4' :from='"ArrivalInfo"' /><br>
            </slot>
          </div>
          <div class="buttons-container">
            <button class="btn-back" @click="($route.query.currentDate || $route.query.orderOnDock) ? goToService('Service', service) : goTo('Service', service)">Back</button>
            <button
              v-if="(getOrderInfo.from === 'Hotel' || getOrderInfo.from === 'Cruise' || getOrderInfo.from === 'Private Address') && getOrderInfo.to === 'Airport'"
              class="btn-next"
              :disabled="(getOrderInfo.from === 'Hotel' && !getOrderInfo.hotelName) ||
                ((!numberOfBags && !numberOfHeavyBags) || (numberOfBags && numberOfHeavyBags && !getOrderInfo.oddSize) || (!numberOfBags && numberOfHeavyBags && !getOrderInfo.oddSize))
                || !getValidator.isValidDate || !getOrderInfo.arrivalDate || !validTime || (getOrderInfo.from === 'Cruise' ?
                (!getOrderInfo.cruiseShip || !getValidator.isValidNumber) : !getValidator.isValidNumber) ||
                (getOrderInfo.from === 'Private Address' ? !getOrderInfo.streetNumberPostal : !getValidator.isValidNumber)"
              @click="($route.query.currentDate || $route.query.orderOnDock) ? goToService('TravellerInfo', service) : goTo('TravellerInfo', service)"
            >Next</button>
            <button
              v-else
              class="btn-next"
              :disabled="(getOrderInfo.from === 'Hotel' && !getOrderInfo.hotelName) ||
                ((!numberOfBags && !numberOfHeavyBags) || (numberOfBags && numberOfHeavyBags && !getOrderInfo.oddSize) || (!numberOfBags && numberOfHeavyBags && !getOrderInfo.oddSize))
                || !getValidator.isValidDate || !getOrderInfo.arrivalDate || !validTime || (getOrderInfo.from === 'Cruise' ?
                (!getOrderInfo.cruiseShip || !getValidator.isValidNumber) : !getValidator.isValidNumber) ||
                (getOrderInfo.from === 'Private Address' ? !getOrderInfo.streetNumberPostal : !getValidator.isValidNumber)"
              @click="($route.query.currentDate || $route.query.orderOnDock) ? goToService('DeliveryInfo', service) : goTo('DeliveryInfo', service)"
            >Next</button>
          </div>
        </div>
      </div>
      <BasketInfo :isArrival="true" :queryPrices="$route.query" class="basket-info-hidden" />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import additionalFunctions from '../mixins/additionalFunctions'
import Header from '@/components/Header'
import Calendar from '@/components/Calendar'
import TimePicker from '@/components/TimePicker'
import ArrivalNumber from '@/components/ArrivalNumber'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import Popup from '@/components/Popup'
import Select from '@/components/Select'
import BasketInfo from '@/components/BasketInfo'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ArrivalInfo',
  components: {
    Header,
    Calendar,
    TimePicker,
    ArrivalNumber,
    Popup,
    ContactUs,
    Select,
    BasketInfo,
    Links
  },
  props: ['service'],
  computed: {
    ...mapGetters(['getOrderInfo', 'getValidator', 'getUrlPrices']),
    validTime () {
      if ((this.getOrderInfo.from === 'Hotel' && this.getOrderInfo.to === 'Cruise') ||
        (this.getOrderInfo.from === 'Cruise' && (this.getOrderInfo.to === 'Hotel' || this.getOrderInfo.to === 'Private Address')) ||
        ((this.getOrderInfo.from === 'Private Address' && this.getOrderInfo.to === 'Cruise'))) {
        return true
      }
      return this.getOrderInfo.arrivalTime
    }
  },
  mixins: [additionalFunctions],
  data () {
    return {
      isBook: false,
      numberOfBags: 0,
      numberOfHeavyBags: 0,
      maxNumberOfBags: 20,
      oddSize: '',
      streetNumberPostal: '',
      apartment: '',
      cruiseShip: '',
      availableDates: [],
      isDataFind: false
    }
  },
  mounted () {
    this.goToMainPage()
  },
  created () {
    if (this.$route.query.availableDates) { // if url parameter availableDates is set
      this.$route.query.availableDates.split(',').forEach((el) => {
        this.availableDates.push(el)
      })
      let day = String(new Date().getUTCDate())
      let month = String(new Date().getUTCMonth() + 1)
      const year = String(new Date().getUTCFullYear())
      if (day.length === 1) {
        day = `0${day}`
      }
      if (month.length === 1) {
        month = `0${month}`
      }
      const newDate = day + '.' + month + '.' + year
      this.isDataFind = this.availableDates.find(el => el === newDate)
      // this.isDataFind = this.availableDates.find(el => new Date(el).toLocaleString().split(',')[0] === new Date().toLocaleString().split(',')[0])
    }
    this.getQueryParams()
    if (this.$route.query.minPrice) {
      this.addNewPriceFromUrl({
        minimumPrice: this.$route.query.minPrice
      })
    }
    if (console) console.log('min price = ' + this.minimumPrice)
    if (this.$route.query.priceBags) {
      this.addNewPriceFromUrl({
        bagPrice: this.$route.query.priceBags
      })
    }
    if (this.$route.query.priceHeavyBags) {
      this.addNewPriceFromUrl({
        bagHeavyPrice: this.$route.query.priceHeavyBags
      })
    }
    if (this.getOrderInfo.numberOfBags) {
      this.numberOfBags = this.getOrderInfo.numberOfBags
    }

    if (this.getOrderInfo.numberOfHeavyBags) {
      this.numberOfHeavyBags = this.getOrderInfo.numberOfHeavyBags
    }

    if (this.getOrderInfo.oddSize) {
      this.oddSize = this.getOrderInfo.oddSize
    }

    if (this.getOrderInfo.streetNumberPostal) {
      this.streetNumberPostal = this.getOrderInfo.streetNumberPostal
    }

    if (this.getOrderInfo.apartment) {
      this.apartment = this.getOrderInfo.apartment
    }

    if (this.getOrderInfo.cruiseShip) {
      this.cruiseShip = this.getOrderInfo.cruiseShip
    }
  },
  methods: {
    ...mapMutations(['addNewPriceFromUrl', 'changeBags', 'changeMainInfo', 'addFromTo', 'addDate', 'addTotalInfo', 'goToMainPage', 'changeCruiseShip', 'changeApartment', 'changeStreetNumberPostal']),
    getQueryParams () {
      if (this.isDataFind) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop)
        })
        const fromTo = { from: params.from, to: params.to }
        const mainQuery = {
          cruiseShip: params.cruiseShip,
          stateroom: params.stateroom
        }
        this.addFromTo({
          from: fromTo.from,
          to: fromTo.to
        })
        if (params.currentDate) {
          this.addDate({ arrivalDate: new Date() })
        }
        if (mainQuery.cruiseShip !== '') {
          this.changeMainInfo({
            cruiseShip: mainQuery.cruiseShip
          })
        }
        if (mainQuery.stateroom !== '') {
          this.changeMainInfo({
            stateroom: mainQuery.stateroom
          })
        }
        return fromTo
      } else {
        this.goToMainPage()
      }
    },
    goTo (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    },
    info () {
      this.addTotalInfo({
        isArrivalInfo: true,
        numberOfBags: this.numberOfBags,
        numberOfHeavyBags: this.numberOfHeavyBags,
        oddSize: this.oddSize,
        streetNumberPostal: this.streetNumberPostal,
        apartment: this.apartment,
        cruiseShip: this.cruiseShip
      })
    },
    setNumberOfBags (value) {
      this.numberOfBags = value
    },
    setNumberOfHeavyBags (value) {
      this.numberOfHeavyBags = value
    }
  },
  watch: {
    numberOfBags: {
      handler: function (val) {
        this.info()
      }
    },
    numberOfHeavyBags: {
      handler: function (val) {
        if (this.numberOfHeavyBags === 0) {
          this.oddSize = ''
          this.addTotalInfo({
            oddSize: ''
          })
        }
        this.info()
      }
    },
    cruiseShip: {
      handler: function (val) {
        this.changeCruiseShip(val)
      }
    },
    apartment: {
      handler: function (val) {
        this.changeApartment(val)
      }
    },
    streetNumberPostal: {
      handler: function (val) {
        this.changeStreetNumberPostal(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  input {
    height: 40px;
  }
  h3 {
    font-family: BlissMedium;
  }
</style>

<style lang="scss">
  .info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -28px;
    min-height: calc(100vh - 39px);
    max-width: 1024px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    @media (min-width: 1023px) {
      margin-right: 15%;
      margin-left: calc(15% + 20px);
      border-left: 2px solid rgb(170, 170, 170);
    }
  }
  .basic {
    letter-spacing: 0.05em;
    font-family: BlissMedium;
  }

  .delivery-info {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }

  .delivery-info--content {
    width: 100%;
    max-width: 500px;
  }

  .orange-box {
    font-family: BlissMedium;
    letter-spacing: 0.07em;
  }

  .delivery-info--content {
    margin: 110px 0 0;
    padding-bottom: 30px;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.07rem;
    width: 100%;
    max-width: 500px;
  }

  .delivery-info--content > div:first-child {
    letter-spacing: 0.05em;
  }

  @media screen and (min-width: 500px) {
    .delivery-info--content {
      font-size: 18px;
    }
  }

  .container-info {
    width: 100%;
    height: auto;
    margin-top: 30px;
    background-color: #E9EDEF;
    box-shadow: 0px 1px 2px 1px #cccccc;
    padding: 10px 15px 10px 15px;
  }

  .container-info >  input[type=text] {
    margin-top: 10px;
  }

  .input_full-width {
    width: 100% !important;
  }

  .delivery-info--content > div:first-child {
    width: 10em;
    height: 2em;
    padding: 10px 0px;
    color: #fff;
    background-color: #FC9000;
    box-shadow: 1px 3px #ababab;
    border-radius: 10px 0px 10px 0px;
    text-align: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .direction {
    margin-top: 30px;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .odd-size {
    display: block;
    animation: fadeIn 1.5s;
    transition: all 1s linear;
    -webkit-animation: fadeIn 1.5s;
  }

  .popup {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  input, select {
    width: 20em;
    line-height: 3em;
    border: 1px solid #cccccc;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 1px #cccccc;
    text-align: center;
  }

  @media screen and (max-width: 350px) {
    .for-popup {
      width: 17em !important;
    }
  }

  @media screen and (max-width: 310px) {
    .for-popup {
      width: 14em !important;
    }

    input, select {
      width: 14em !important;
    }
  }

  select {
    height: 40px;
    cursor: pointer;
  }

  option {
    background-color: #d4d5d6;
    margin: 6em 1em 1em 1em;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.1rem;
    color:#000000;
  }

  .buttons-container {
    margin: 20px 0 0;
  }

  .invalid {
    border: 1px solid #ff0000;
  }

  small {
    display: block;
    margin-top: 0.2em;
    color: #ff0000;
    font-family: BlissLight;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1rem;
  }

  .m-top {
    margin-top: 10px;
  }

  textarea {
    width: 100%;
    border: 1px solid #53565A;
    border-radius: 10px;
    font-family: BlissLight;
    font-weight: 500;
    font-size: 20px;
    -webkit-box-shadow: inset 0px 0.5px 1px 0.5px #53565A;
    -moz-box-shadow: inset 0px 0.5px 1px 0.5px #53565A;
    box-shadow: inset 0px 0.5px 1px 0.5px #cccccc;
  }

  .basket-info-hidden {
    @media (max-width: 800px) {
      display: none;
    }
  }
</style>
