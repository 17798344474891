<template>
  <div class="home">
    <Header :isBook='isBook' :isArrival='false'/>
    <div class="info-wrapper">
      <div class="delivery-info-sections">
        <div class="delivery-info--content">
          <div class="orange-box">Storage info</div>
          <div class='container-info'>
            <Popup :fieldName='"No. of bags*"' :header='"Bag types"'/>
            <!-- <input type="text" v-model="numberOfBags" v-on:change="info" required><br> -->
            <Select @vue-selected="setNumberOfBags" :selectedNum="getStorageInfo.numberOfBags" :count="20" :disOption="0" required/>
            <small v-if="/\D/.test(numberOfBags) || numberOfBags === '' || numberOfBags > maxNumberOfBags">
              This value can only be integer, no more than {{ this.maxNumberOfBags }}, and cannot be empty
            </small><br>
            <Popup :fieldName='"No. of odd-siz"' :header='"Bag types"'/>
            <!-- <input type="text" v-model="numberOfHeavyBags" v-on:change="info" required><br> -->
            <Select @vue-selected="setNumberOfHeavyBags" :selectedNum="getStorageInfo.numberOfHeavyBags" :count="6" :disOption="0" required/>
            <small v-if="/\D/.test(numberOfHeavyBags) || numberOfHeavyBags > maxNumberOfBags">
              This value can only be integer or empty and no more than {{ this.maxNumberOfBags }}
            </small><br>
            <slot v-if="getStorageInfo.numberOfHeavyBags > 0">
              <p>What kind of odd-size luggage?</p>
              <input type="text" class="input_full-width" v-model="oddSize" v-on:change="info" placeholder="e.g. bicycle, heavy luggage, golfbag" required><br>
              <small v-if="getStorageInfo.numberOfHeavyBags > 0 && !getStorageInfo.oddSize">
                This field must be specified if you have selected odd-size luggage
              </small><br>
            </slot>
          </div>
        </div>
        <div class="delivery-info--content m-delivery-info">
          <div class="orange-box">Date of storage</div>
          <div class='container-info'>
            <div class="calendar-label basic">Storage from (date)* <br>Call us for same day service</div>
            <Calendar :calendarId='2' :placeholder='"e.g. 2021-12-21"'/>
            <div class="calendar-label basic">Storage from (time)*</div>
            <TimePicker :timePickerId='2' :disOption='"e.g. 8 am - 9 am"' :season='"test"'/>
            <p class="basic">Arrival flight (if known)</p>
            <ArrivalNumber :arrivalNumberId='1' :from='"StorageInfo"'/><br>
            <!-- <p class="basic">Arrival Flight Number (if known)</p>
            <ArrivalNumber :arrivalNumberId='1' :from='"StorageInfo"'/><br> -->
            <h3><span>Storage Pick-Up</span></h3><br>
            <div class="calendar-label basic">Storage End (Date)*</div>
            <Calendar :calendarId='3' :placeholder='"e.g. 2021-12-24"' :minDate="minDate"/>
            <div class="calendar-label basic">Storage End (Time)*</div>
            <TimePicker :timePickerId='3' :disOption='"e.g. 8 am - 9 am"'/>
            <!-- <h3><span>Arrival Flight Numbers</span></h3><br> -->
            <!-- <p class="basic">Arrival Flight Number (if known)</p>
            <ArrivalNumber :arrivalNumberId='1' :from='"StorageInfo"'/><br> -->
            <p class="basic">Arrival flight on pick-up (if known)</p>
            <ArrivalNumber :arrivalNumberId='0' :from='"StorageInfo"'/><br>
          </div>
          <div class="buttons-container m-buttons-container">
            <button class="btn-back" onclick="location.href='/#service'">Back</button>
            <button class="btn-next" :disabled="(/\D/.test(numberOfBags) || numberOfBags === '') ||
                    /\D/.test(numberOfHeavyBags) || !getValidator.isValidNumber ||
                    (getStorageInfo.numberOfHeavyBags > 0 && !getStorageInfo.oddSize) ||
                    !getStorageInfo.storageDateFrom || !getStorageInfo.storageDateEnd ||
                    !getStorageInfo.storageTimeFrom || !getStorageInfo.storageTimeEnd"
                    @click="goToService('TravellerInfo', service)">Next</button>
          </div>
        </div>
      </div>
      <BasketInfo :isArrival='false' class="basket-info-hidden" />
    </div>
    <Links link-location="row" />
    <ContactUs />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Calendar from '@/components/Calendar'
import TimePicker from '@/components/TimePicker'
import ArrivalNumber from '@/components/ArrivalNumber'
import Popup from '@/components/Popup'
import Links from '@/components/TCAndPrivacyPolicyLinks'
import ContactUs from '@/components/ContactUs'
import Select from '@/components/Select'
import BasketInfo from '@/components/BasketInfo'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'StorageInfo',
  components: {
    Header,
    Calendar,
    TimePicker,
    ArrivalNumber,
    Popup,
    ContactUs,
    Select,
    BasketInfo,
    Links
  },
  props: ['service'],
  computed: {
    ...mapGetters(['getStorageInfo', 'getValidator', 'getOrderInfo']),
    storageDays () {
      const dateFrom = this.getStorageInfo.storageDateFrom
      const dateEnd = this.getStorageInfo.storageDateEnd
      if (dateFrom && dateEnd) {
        const days = parseInt(Math.floor(new Date(dateEnd).getTime() - new Date(dateFrom).getTime()) / (1000 * 60 * 60 * 24))
        return days > 1 ? days : 1
      }
      return 0
    },
    minDate () {
      const firstCalendarDateMonth = new Date(this.getStorageInfo.storageDateFrom).getMonth()
      const firstCalendarDateDay = new Date(this.getStorageInfo.storageDateFrom).getDate()
      const firstCalendarDateYear = new Date(this.getStorageInfo.storageDateFrom).getFullYear()

      const currentDateMonth = new Date().getMonth()
      const currentDateDay = new Date().getDate()
      const currentDateYear = new Date().getFullYear()

      console.log(`${currentDateMonth + 1}/${currentDateDay}/${currentDateYear}`)
      const firstCalendarDate = `${firstCalendarDateMonth + 1}/${firstCalendarDateDay}/${firstCalendarDateYear}`
      const currentDate = `${currentDateMonth + 1}/${currentDateDay}/${currentDateYear}`
      return this.getNumberOfDays(currentDate, firstCalendarDate)
    }
  },
  data () {
    return {
      isBook: false,
      numberOfBags: 0,
      numberOfHeavyBags: 0,
      maxNumberOfBags: 20,
      oddSize: ''
    }
  },
  created () {
    if (this.getStorageInfo.numberOfBags) {
      this.numberOfBags = this.getStorageInfo.numberOfBags
    }

    if (this.getStorageInfo.numberOfHeavyBags) {
      this.numberOfHeavyBags = this.getStorageInfo.numberOfHeavyBags
    }

    if (this.getStorageInfo.oddSize) {
      this.oddSize = this.getStorageInfo.oddSize
    }
    // this.getQueryParams2()
    if (this.$route.query.stoFee) {
      this.addNewPriceFromUrl({
        stoPrice: this.$route.query.stoFee
      })
    }
  },
  methods: {
    ...mapMutations(['addNewPriceFromUrl', 'addTotalInfo', 'setStorageDays', 'addFromTo']),
    getQueryParams2 () {
      // if (this.isDataFind) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      })
      return params
    },
    getNumberOfDays (start, end) {
      const date1 = new Date(start)
      const date2 = new Date(end)

      const oneDay = 1000 * 60 * 60 * 24

      const diffInTime = date2.getTime() - date1.getTime()

      const diffInDays = Math.round(diffInTime / oneDay)

      return diffInDays
    },
    info () {
      this.addTotalInfo({
        isArrivalInfo: false,
        numberOfBags: this.numberOfBags,
        numberOfHeavyBags: this.numberOfHeavyBags,
        oddSize: this.oddSize
      })
    },
    setNumberOfBags (value) {
      this.numberOfBags = value
    },
    setNumberOfHeavyBags (value) {
      this.numberOfHeavyBags = value
    },
    goToService (name, service) {
      this.addFromTo({
        from: service
      })

      this.$router.push({
        name: name,
        params: {
          service: service,
          services: this.getOrderInfo.services
        }
      })
    }
  },
  watch: {
    numberOfBags: {
      handler: function (val) {
        this.info()
      }
    },
    numberOfHeavyBags: {
      handler: function (val) {
        this.info()
      }
    },
    storageDays () {
      this.setStorageDays(this.storageDays)
    }
  }
}
</script>

<style scoped lang="scss">
  .m-delivery-info {
    margin-top: 0px !important
  }

  @media screen and (min-width: 960px) {
    .m-delivery-info {
      margin-top: -20px !important
    }
  }

  h3 {
   width: auto;
   text-align: center;
   border-bottom: 2px solid #cccccc;
   font-weight: bold;
   line-height: 0.1em;
   margin: 10px 0 20px;
  }

  h3 span {
    background:#E9EDEF;
    padding: 0 10px;
  }

  .delivery-info-sections {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .delivery-info {
    width: 100%;
  }

  .basket-info-hidden {
    @media (max-width: 800px) {
      display: none;
    }
  }
</style>
